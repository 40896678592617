import React, {useState,useEffect} from 'react';
import Avatar from '../../../node_modules/@material-ui/core/Avatar';
import Button from '../../../node_modules/@material-ui/core/Button';
import CssBaseline from '../../../node_modules/@material-ui/core/CssBaseline';
import TextField from '../../../node_modules/@material-ui/core/TextField';
import Typography from '../../../node_modules/@material-ui/core/Typography';
import { makeStyles } from '../../../node_modules/@material-ui/core/styles';
import Container from '../../../node_modules/@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom';
import {verifyOtp,  resendOtp} from '../../actions/authActions';
import axios from 'axios';
import {FETCH_LOGIN, FETCH_OTP, FETCH_REGISTER,IS_AUTHENTICATED} from '../../reducers/types/authTypes';
import jwt from 'jsonwebtoken'
import { trackPromise } from 'react-promise-tracker'
import 'react-toastify/dist/ReactToastify.css';

import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import history from '../../history';
import {  toast } from 'react-toastify';

export default function LoginPage(props) {

const paydone = localStorage.removeItem('paydone')

const dispatch = useDispatch()
const authData = useSelector(state => state.auth.otp)
const [otpExpired, setOtpExpired] = useState(false)
const [otpSuccess, setOtpSuccess] = useState(false)
const [errorText, setErrorText] = useState({ error: false, error_text: "" });
//const isAuthenticated = useSelector(state => state.auth.isAuthenticated)


sessionStorage.removeItem('TrustcareGroup' )  
axios.interceptors.response.use(undefined, (err) => {
  const { config, message } = err;
  if (!config || !config.retry) {
    return Promise.reject(err);
  }
  // retry while Network timeout or Network Error
  if (!(message.includes("timeout") || message.includes("Network Error"))) {
//    toast.error("err 2")
 //   return Promise.reject(err);
  }
  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      toast.error("Please wait")
      console.log("retry the request", config.url);
      resolve();
    }, config.retryDelay || 1000);
  });
  return delayRetryRequest.then(() => axios(config));
});


const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

function setIsAuthenticated(user){
  return {
      type: IS_AUTHENTICATED,
      payload: user
  }
}
function setAuthorizationToken (token){

  
  if(token){
        return {headers:{
            'Authorization': `Bearer ${token}`
        }}
    }
    else {
        return false    
    }


}
const config = setAuthorizationToken("MiA4MzA0In0.Lbs5QaXwinnwsKwpfy3APkO3poUjBljVOOcIk6-XUIQTEST")


const [form, setForm] = useState({
  contact_number: "",
  code: ""
});

useEffect(() => {
  var match = document.cookie.match(new RegExp('(^| )' + 'otp_number' + '=([^;]+)'));
if (!match){
  setOtpExpired(true)
}
else{
  setForm({...form, contact_number : match[2]})
}


  
}, []);

useEffect(() => {


  var contact_number = sessionStorage.getItem('otpno')
  var code = sessionStorage.getItem('otpcode')
  var username = sessionStorage.getItem('username')
 
 // toast.success(username);
  
  if (code!=='9999')  
  trackPromise (axios.post(`https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api/auth/verify-user`, {contact_number, code }, config))
      .then(response => {
        console.log('-------------------------------------------------------------------DRANIX API CALL ONCE');
        console.log(response.data);
  
        
        const setlocalStorage =  localStorage.setItem('jwtToken', response.data.access_token)
        const setAuth =  setIsAuthenticated(jwt.decode(response.data.access_token))

        if (response.data.message=='not verified sms' )
        {
          if (code!=='9999') {toast.success("Wrong OTP")}
        }
        else
        {
          window.location.replace("/otp")
        }
        
        
      })
  


  if(authData.message == "Success Login"){
    
    console.log(authData)

    //setOtpSuccess(true)
  }
  

  else if(authData.message == "not verified"){
    setErrorText({ error_text: "Incorrect Code", error: true });
    toast.error(authData.message)

  }
  else if(authData.message == "user is blocked"){
    setErrorText({ error_text: "User is Blocked", error: true });
    toast.error(authData.message)
  }

  
  }, [authData]);

  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

  const classes = useStyles();

  if(otpExpired){
    return <Redirect to='/dranix' />;

  }

  if(otpSuccess){
    return <Redirect to='/' />;

  }

  function handleChange(event) {
    const value = event.target.value;

    setForm({ ...form, [event.target.name]: value });
  }

    function submitForm() {

    var contact_number = form.contact_number;
    var code = form.code;

    sessionStorage.setItem('otpcode', code)
    sessionStorage.setItem('otpno', contact_number)
//    window.location.replace("/otp")

toast.success("Verify OTP")
       
          dispatch(verifyOtp(form));             
      

  
  }


  function resendCode(){
    dispatch(resendOtp(form.contact_number))
  }
  if(isAuthenticated){
    history.push('/')
  }
  
 

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
        </Avatar>
        
        <Typography component="h1" variant="h5">
          OTP
        </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="number"
            label="Enter 4 digit Number"
            name="code"
            autoFocus
            onChange={handleChange}
            error={errorText.error}
            helperText={errorText.error_text}
          />
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitForm}

          >
            Submit
          </Button>
          <Grid container>
              <Grid item xs>
                <Link onClick={resendCode} variant="body2">
                  Resend Code?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                </Link>
              </Grid>
            </Grid>
      </div>
     
    </Container>
  );
}