import {FETCH_LOGIN, FETCH_OTP, FETCH_REGISTER,IS_AUTHENTICATED} from '../reducers/types/authTypes';
import {IS_LOADING} from '../reducers/types/loadingTypes';
import axios from 'axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken'
import { trackPromise } from 'react-promise-tracker'
import { confirmAlert } from 'react-confirm-alert'; // Import
import { TheatersRounded } from '@material-ui/icons';
import moment from "moment";

const apiUrl = 'https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api'
const apiUrl_backup = 'https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api'

// https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api

function wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
  }
  

  axios.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
  //    toast.error("err 2")
   //   return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        toast.error("Please wait")
        console.log("retry the request", config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
  });
  

export default function setAuthorizationToken (token){

    if(token){
        return {headers:{
            'Authorization': `Bearer ${token}`
        }}
    }
    else {
        return false    
    }
}

export function setIsAuthenticated(user){
    return {
        type: IS_AUTHENTICATED,
        payload: user
    }
}
 
const config = setAuthorizationToken(localStorage.jwtToken)


export const login = (user) => async dispatch =>{

    
  const paydone = localStorage.removeItem('paydone')

  
  var  shopname = sessionStorage.getItem('shop')
  toast.error(shopname);

  var  res_data = sessionStorage.getItem('res_data')
  const {username, password} = user

  var res;
  sessionStorage.setItem('otpcode', "9999")
  sessionStorage.setItem('otpno', "+6596398894")

  sessionStorage.setItem('username', username)

//   dispatch(login(form))   
    
      //axios.post(`${apiUrl}/dra/forgotpassword`, config)
      //axios.post(`${apiUrl}/dra/forgotpassword`, {username, password}, config )
      var mypassword = encodeURIComponent(password);
      var myusername = encodeURIComponent(username);
      var myshopname = encodeURIComponent(shopname);
//'        res = await (trackPromise (axios.get(`https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api/dra/login?username=`+myusername+'&password='+mypassword+'&shopname='+myshopname, {username, password,shopname}, config )))
var i;
var test = 'y';

try {
for (i = 0; i < 5; i++) {
//  toast.success('Server...')
  res = await trackPromise(axios.post(`${apiUrl}/paypal-success-temp`, { retry: 3, retryDelay: 3000,  username,  test}))
  
  if(res.data.message === "testing : ") 
    {
    //    toast.error('Please wait')
        break;
    }    
  
  
}
} catch (error){}
    try {
      for (i = 0; i < 5; i++) {
        //  toast.success('Server...')
     //     res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,   test}))
          
          if(res.data.message === "testing : ") 
            {
      //          toast.error('Please wait')
                break;
            }    
          
          
      }
      } catch (error){}   
   
    try{
        
        
        var mycount
        mycount = 0
    
        
        for (i = 0; i < 5; i++) {
      //      toast.success('Message : Login. Sending OTP.');
    
     //       res = await trackPromise(axios.post(`${apiUrl}/auth/login`, {username, password,shopname}, config))
      //      toast.success('Message : Login. Sending OTP.');
        var mypassword = encodeURIComponent(password);
        var myusername = encodeURIComponent(username);
        var myshopname = encodeURIComponent(shopname);  
//            res = await trackPromise(axios.post(`https://trustcaretriggereast.azurewebsites.net/online-pdms/public/api/trustcare/auth/login`, {username, password,shopname}, config))

  //https://trustcaretriggereast.azurewebsites.net/online-pdms/public/api/trustcare/auth/login
   res = await trackPromise(axios.get("https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api/dra/login?username="+username+"&password="+mypassword+"&shopname="+shopname))
     
            console.log(res.data, 'login')
            if(res) break;
            if (mycount > 3) 
                if(!res) break;
            mycount = mycount + 1 
            
        }

            if(res){

       
                localStorage.setItem('login', 'yes')  
                const setlocalStorage = await localStorage.setItem('jwtToken', res.data.token)
                const setAuth = await setIsAuthenticated(jwt.decode(res.data.token))
                toast.success("Connected")
      
       
                dispatch({
                    type: FETCH_LOGIN,
                    payload: res.data
                 });
                
            }
       
        } catch (error){

           toast.error("Pls try again later");
          //  toast.error(error);


          
        

        }


        var start = new Date().getTime();
        localStorage.setItem('date1',start)
        const done = localStorage.getItem('date1')

        if(!res){

          try{
        
        
            var mycount
            mycount = 0
        
            
            for (i = 0; i < 5; i++) {
          //      toast.success('Message : Login. Sending OTP.');
        
         //       res = await trackPromise(axios.post(`${apiUrl}/auth/login`, {username, password,shopname}, config))
          //      toast.success('Message : Login. Sending OTP.');
            var mypassword = encodeURIComponent(password);
            var myusername = encodeURIComponent(username);
            var myshopname = encodeURIComponent(shopname);  
    //            res = await trackPromise(axios.post(`https://trustcaretriggereast.azurewebsites.net/online-pdms/public/api/trustcare/auth/login`, {username, password,shopname}, config))
    
      //https://trustcaretriggereast.azurewebsites.net/online-pdms/public/api/trustcare/auth/login
       res = await trackPromise(axios.get("https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api/dra/login?username="+username+"&password="+mypassword+"&shopname="+shopname))
         
                console.log(res.data, 'login')
                if(res) break;
                if (mycount > 3) 
                    if(!res) break;
                mycount = mycount + 1 
                
            }
    
                if(res){
    
           
                    localStorage.setItem('login', 'yes')  
                    const setlocalStorage = await localStorage.setItem('jwtToken', res.data.token)
                    const setAuth = await setIsAuthenticated(jwt.decode(res.data.token))
                    toast.success("Connected")
          
           
                    dispatch({
                        type: FETCH_LOGIN,
                        payload: res.data
                     });
                    
                }
           
            } catch (error){
    
               toast.error("Pls try again later");
              //  toast.error(error);
    
    
              
            
    
            }
    
        }  
  
    
}

export const register =  (user) => async dispatch =>{

    const {username, password,password_confirmation,sms_handphone,email,alias_username} = user
    try{
       const res = await trackPromise(axios.post(`${apiUrl}/auth/register`, {username, password,password_confirmation,sms_handphone,email,alias_username}, config))
       if(res.data.error){
    //    toast.error(res.data.error);
        toast.error('Clear Browser Cache');
        return false
       }
       else{
  //      toast.error('NO ERROR');
     
 /*       dispatch({
            type: FETCH_REGISTER,
            payload: res.data
         });
*/

confirmAlert({
    title: 'Registration',
    message: 'Account activation within 24hrs.',
    buttons: [
//      {
 //       label: 'Logout',
  //      onClick: () => dispatch(logout())
   //   },
      {
        label: 'OK',
        onClick: () => dispatch(logout())
      }
    ]
  });
}

       

       
        } catch (error){

            toast.error("Internal Server Error");
        }


}


export const logout =  () => async dispatch =>{

       
  
  try {  
    const done1 = localStorage.getItem('date1')
  //  //toast.error(done1);
    var now = new Date().getTime();
    var duration = now - done1
    var dur1 =  duration / 10000
    
  //  //toast.error(dur1);
    }

     catch (error){
        
    // //toast.error("Internal Server Error");
    }


    

    var jwt
    var done
    var iflogin
    var imessage

    jwt = sessionStorage.getItem("jwtToken");
    done = sessionStorage.getItem("logout");
    iflogin = localStorage.getItem("mylogin");
    imessage = localStorage.getItem("mymessage");


   

        
   
   
                localStorage.removeItem("jwtToken")  
                sessionStorage.setItem('Logout', 'done');
                
            
     
            if (dur1 < 100)
            { 

    }
   

    
    // logout - regardless server status

  

    localStorage.removeItem("mylogin")  
                

  var shop = sessionStorage.getItem('shop')  
  var shopname=""
 
    shopname = 'Session Expired'
    window.location.replace('/')
  

  
//    try{
     //  const res = await trackPromise(axios.get(`${apiUrl}/auth/logout`, config))
    //   if(!res.data){
     //   //toast.error(res.data.error);
     //   return false
     //  }
       ///else if (res.data.message == 'Successfully logged out')
  //     {

     //   const removeLocalStorage = localStorage.removeItem("jwtToken");
    //    window.location.replace('/login')

       
    //   }

       
      //  } catch (error){


        //    if(error.message == "Token has Expired"){
         //       window.location.replace("login")
          //  }
          //  //toast.error("Internal Server Error");
      //  }


}





export const mypaynow =  () => {


    
    window.location.replace('/p')



}


export const paycallback =  () => {
   

    var shop = sessionStorage.getItem('shop')  
    toast.success('Paynowcallback')
    window.location.replace('/paynowcallback')
  
    }

    

export const verifyOtp = (form) => async dispatch =>{

    const {contact_number, code} = form
    const paydone = localStorage.removeItem('paydone')
    var mypass = encodeURIComponent(contact_number);

   var i = 0;
   var res;
     
    var test = 'y';
      
    try {
    for (i = 0; i < 5; i++) {
      //  toast.success('Server...')

      // https://trustcaretriggereast.azurewebsites.net/online-pdms/public/api/trustcare/auth/verify-user
      //  res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,   test}))
        res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,   test}))
        
        if(res.data.message === "testing : ") 
          {
              toast.error('Please wait')
              break;
          }    
        
        
    }
    } catch (error){}   

    
    try{
        res = await trackPromise(axios.get(`https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api/dra/verify-user?code=`+code+'&contact_number='+mypass,  {retry: 15, retryDelay: 5000},config))
    //    res = await trackPromise(axios.post(`https://trustcaretriggereast.azurewebsites.net/online-pdms/public/api/trustcare/auth/verify-user`, {contact_number,code}, config))
        
        if(!res){
            console.log('No Data Found')
        }
        else if (res.data.access_token){

            const setlocalStorage = await localStorage.setItem('jwtToken', res.data.access_token)
            const setAuth = await setIsAuthenticated(jwt.decode(res.data.access_token))

         //   toast.success(res.data.access_token)

            window.location.replace("/")
            // dispatch({
            //     type: FETCH_OTP,
            //     payload: {message : 'Success Login', token : res.data.access_token}
            //  });
        }
        else{
            dispatch({
                type: FETCH_OTP,
                payload: res.data
             });
        }
        

        } catch (error){

            
    try{
      var res = await trackPromise(axios.get(`https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api/dra/verify-user?code=`+code+'&contact_number='+mypass,  {retry: 5, retryDelay: 4000},config))
      
      if(!res){
          console.log('No Data Found')
      }
      else if (res.data.access_token){

          const setlocalStorage = await localStorage.setItem('jwtToken', res.data.access_token)
          const setAuth = await setIsAuthenticated(jwt.decode(res.data.access_token))

       //   toast.success(res.data.access_token)

          window.location.replace("/")
          // dispatch({
          //     type: FETCH_OTP,
          //     payload: {message : 'Success Login', token : res.data.access_token}
          //  });
      }
      else{
          dispatch({
              type: FETCH_OTP,
              payload: res.data
           });
      }
      

      } catch (error){

          toast.error("Internal server error")
          return "Internal server error"
      }

        }


}




export const resendOtp = (number) => async dispatch =>{

  var test = 'y';
  var username = sessionStorage.getItem('username')  ;

    try{
        const res = await trackPromise(axios.post(`${apiUrl}/auth/resend-sms`, {username,number}, config))
        
        if(!res){
            console.log('No Data Found')
        }
        
        else{
           toast.success('Code Resend Succesful : '+username)
        }
        

        } catch (error){

            return "Internal server error"
        }


}

